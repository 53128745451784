import type { ContentfulAsset, ContentfulEntity } from '@models/contentful';
import React from 'react';
import { omit } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import AppReducer, { type AppStateActions } from '@context/appReducer';
import { type AppState, initialState } from '@context/appState';
import type { Context } from '@context/global';
import { defaultLocale } from '@helpers';
import MicrocopyHelper from '@helpers/microcopy';

export type AppContext = Context<AppState, AppStateActions>;

export const AppStateContext = React.createContext<AppContext>([
    initialState,
    (value) => {},
]);

export const AppProvider = ({ children, context }) => {
    initialState.id = context.id ?? null;
    initialState.locale = context.locale ?? defaultLocale;
    initialState.slug = context.slug;

    const data = useStaticQuery(graphql`
        query {
            footerMicrocopy: allContentfulResourceSet(filter: { name: { eq: "footer" } }) {
                ...resourceNodes
            }
            assets: allContentfulAsset {
                ...assetNodes
            }
            pages: allContentfulContentPage {
                nodes {
                    id: contentful_id
                    locale: node_locale
                    slug
                }
            }
        }
    `);

    initialState.microcopy = new MicrocopyHelper(initialState.locale)
        .add(data.footerMicrocopy.nodes);

    initialState.assets = data.assets.nodes.reduce((acc: any, node: ContentfulAsset) => {
        acc[node.id] = omit(node, [ 'id' ]);

        return acc;
    }, {});

    initialState.pages = data.pages.nodes.reduce((acc: any, node: ContentfulEntity) => {
        acc[node.id] ||= {};
        acc[node.id][node.locale] = node.slug;

        return acc;
    }, {});

    return (
        <AppStateContext.Provider value={ React.useReducer(AppReducer, initialState) }>
            { children }
        </AppStateContext.Provider>
    );
};

export const useAppState = (): AppContext => React.useContext(AppStateContext);
