import React from 'react';
import * as ReactDOM from 'react-dom/client';
import type { GatsbyBrowser } from 'gatsby';
import { GlobalStateProvider } from './src/context';

import '@styles/global.css';
import '@styles/concept.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
    <GlobalStateProvider>{ element }</GlobalStateProvider>
);

export const replaceHydrateFunction = () => {
    return (element: any, container: any) => {
        ReactDOM.createRoot(container)
                .render(element);
    };
};