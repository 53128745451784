export const align = (value: string): string =>
    value === 'Left'
        ? 'items-start'
        : value === 'Right'
            ? 'items-end'
            : value === 'Center'
                ? 'items-center'
                : 'items-start'; // default

export const justify = (value: string): string =>
    value === 'Top'
        ? 'justify-start'
        : value === 'Bottom'
            ? 'justify-end'
            : value === 'Center'
                ? 'justify-center'
                : 'justify-start'; // default

export const textAlign = (value: string): string => `text-${ value.toLowerCase() }`;

export const bgColor = (value?: string): string => value ? `bg-[${ value }]` : '';

export const gridCols = (value: number): string => `grid grid-cols-1 md:grid-cols-${ value }`;

// dark bg colors are commented
export const theme = (value?: string) => isLight(value) ? 'theme-light' : 'theme-dark';

export const isLight = (value?: string) => [
    '#ffffff',
    // '#000000',
    // '#00b1f7',
    // '#083141',
    // '#4e7586',
    '#f2f6ff',
    // '#5a5a5a',
    '#f4f4f4',
    undefined,
    null,
    ''
].includes(value);
