import { AppState } from './appState';

export interface AppStateActions extends Record<string, any> {
    type: 'SET_HEADER_ACTIVE' | string;
}

const AppReducer = (state: AppState, action: AppStateActions): AppState => {
    switch (action.type) {
        case 'SET_IS_LIVE_PREVIEW':
            return {
                ...state,
                isLivePreview: action.isLivePreview,
            };
        case 'SET_LOCALE':
            return {
                ...state,
                locale: action.locale,
            };
        default:
            return state;
    }
};

export default AppReducer;
