import path from 'path';
import type { CreatePagesArgs } from 'gatsby';
import type { ContentPage, HomePage } from '@models/contentful';
import { defaultLocale } from '../helpers';

interface Route {
    path: string,
    component: string,
    context: any
}

export const getHomePageRoutes = async (graphql: CreatePagesArgs['graphql']): Promise<Route[]> => {
    const { data } = await graphql<{ pages: { nodes: HomePage[] } }>(`
        query {
            pages: allContentfulHomePage {
                nodes {
                    node_locale
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.pages.nodes.forEach(page => routes.push({
        path: page.node_locale === defaultLocale ? '/' : `/${ page.node_locale }`,
        component: path.resolve('./src/templates/homePage.tsx'),
        context: {
            locale: page.node_locale,
        },
    }));

    return routes;
};
export const getNotFoundPageRoutes = async (graphql: CreatePagesArgs['graphql']): Promise<Route[]> => {
    const { data } = await graphql<{ pages: { nodes: HomePage[] } }>(`
        query {
            pages: allContentfulNotFoundPage {
                nodes {
                    node_locale
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.pages.nodes.forEach(page =>
        routes.push({
            path: page.node_locale === defaultLocale ? '404' : `${ page.node_locale }/404`,
            component: path.resolve('./src/templates/notFoundPage.tsx'),
            context: {
                locale: page.node_locale,
                slug: '404',
            },
        }),
    );

    return routes;
};

export const getContentPageRoutes = async (graphql: CreatePagesArgs['graphql']): Promise<Route[]> => {
    const { data } = await graphql<{ pages: { nodes: ContentPage[] } }>(`
        query {
            pages: allContentfulContentPage {
                nodes {
                    contentful_id
                    node_locale
                    slug
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.pages.nodes.forEach(page => routes.push({
        path: `${ page.node_locale }/${ page.slug }`,
        component: path.resolve('./src/templates/contentPage.tsx'),
        context: {
            id: page.contentful_id,
            locale: page.node_locale,
            slug: page.slug,
        },
    }));

    return routes;
};
