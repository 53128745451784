import React from 'react';
import { ThemeProvider } from '@material-tailwind/react';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { AppProvider } from '@context/appProvider';

export type Context<STATE, ACTIONS> = [ STATE, React.Dispatch<ACTIONS> ];

export const GlobalStateProvider = ({ children }) => (
    <QueryParamProvider adapter={ ReachAdapter }>
        <AppProvider context={ children.props.pageContext }>
            <ThemeProvider>
                { children }
            </ThemeProvider>
        </AppProvider>
    </QueryParamProvider>
);
