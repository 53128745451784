import { get, set } from 'lodash';

interface Microcopy {
    key: string;
    value: { value: string };
    node_locale: string;
}

interface MicrocopyNode {
    resources: Microcopy[];
}

export default class MicrocopyHelper {
    private items = {};

    constructor(private locale: string) { }

    public add(contentfulResourceSet: MicrocopyNode[]) {
        this.prepare(contentfulResourceSet);

        return this;
    }

    public get(key: string, defaultValue: string = '') {
        return get(this.items, [ this.locale, key ])?.value ?? defaultValue;
    }

    private prepare(nodes: MicrocopyNode[]) {
        nodes.forEach(node => {
            node.resources.forEach(item => {
                set(
                    this.items,
                    [
                        item.node_locale,
                        item.key,
                    ],
                    {
                        value: item.value?.value,
                    },
                );
            });
        }, this.items);
    }
}
