import { defaultLocale, isBrowser } from './common';

export const UrlHelper = {
    getHomeUrl: (locale: string) =>
        locale === defaultLocale ? '/' : `/${ locale }`,

    getUrl: (locale: string, slug: string) => {
        if (slug === '404')
            return locale === defaultLocale ? '/404' : `/${ locale }/404`;

        return slug ? `/${ locale }/${ slug }` : UrlHelper.getHomeUrl(locale);
    },

    getFullUrl: (locale: string, slug: string) => [
        isBrowser && window.location.protocol + '//',
        isBrowser && window.location.host,
        locale === defaultLocale && !slug ? '' : UrlHelper.getUrl(locale, slug),
    ].filter(Boolean)
        .join(''),
};
