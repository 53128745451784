import { defaultLocale } from '@helpers';
import MicrocopyHelper from '@helpers/microcopy';
import { type ContentfulAsset, type ContentfulEntity, type Locale } from '@models/contentful';

export interface AppState {
    id: string;
    locale: Locale;
    slug: string;
    microcopy: MicrocopyHelper;
    isLivePreview: boolean;
    assets: ContentfulAsset[];
    pages: ContentfulEntity[];
}

export const initialState: AppState = {
    id: '',
    locale: defaultLocale,
    slug: '/',
    microcopy: new MicrocopyHelper(defaultLocale),
    isLivePreview: false,
    assets: [],
    pages: [],
};
